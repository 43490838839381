<template>
  <div>
    <NavPackage/>
    <div class="container-history history-info">
        <h1>Payment History</h1><br><br>
        <div class="card w-100">
            <div v-if="listPaymentHistory.length >= 1" class="card-body">
                <div class="row">
                    <div class="col-6">
                        <p>Current Plan</p>
                        <p><span>{{listPaymentHistory[0].package.name_package}}</span></p>
                    </div>
                    <div class="col-6">
                        <p>Next Payment</p>
                        <p><span>{{convertDate(listPaymentHistory[0].end)}}</span></p>
                    </div>
                </div>
            </div>
            <div v-if="listPaymentHistory.length <= 0" class="card-body">
                <div class="row">
                    <div class="col-6">
                        <p>Current Plan</p>
                        <p><span>Free</span></p>
                    </div>
                    <div class="col-6">
                        <p>Next Payment</p>
                        <p><span>-</span></p>
                    </div>
                </div>
            </div>
        </div>
        <table class="table table-sm mt-3 table-borderless titles">
            <thead>
                <tr>
                    <th>Date</th>
                    <th class="text-center ">Plan</th>
                    <th class="text-center">Status</th>
                    <th class="text-center">Amount</th>
                    <th class="text-right">Invoice</th>
                </tr>
            </thead>
            <tbody v-if="listPaymentHistory.length >= 1">
                <tr v-for="(history, index) in listPaymentHistory" :key="index">
                    <td class="blue-text">{{convertDate(history.start_from)}}</td>
                    <td class="text-center">{{history.package ? history.package.name_package : ''}}</td>
                    <td class="text-center"><span :id="history.status.toLowerCase() == 'paid' ? 'green-text'
                     : history.status.toLowerCase() == 'rejected' ? 'red-text'
                     : 'black-text'">{{history.status}}</span></td>
                    <td class="text-center">${{history.package ? history.package.price_year : ''}}</td>
                    <td class="text-right"><a :href="history.invoice_pdf" class="btn btn-details">Details</a></td>
                </tr>
            </tbody>
        </table>
        <div  v-if="listPaymentHistory.length >= 1" class="note-text">NOTE: We only show up 1 year of payment history.</div>
        <div v-if="listPaymentHistory.length <= 0" class="d-flex flex-column justify-content-center align-items-center">
            <span class="mt-4"></span><br>
            <img src="@/assets/images/no-payment-histories.png" alt class="img-fit mb-4" />
            <p class="mb-1 pb-0 default-text-style-w">No Payment History.</p>
            <p class="text-muted pt-0 mt-0">Payment history will be displayed here after you purchase your plan.</p>
        </div>
    </div>
        <div class="container-history">
            <footer class="mt-5">
                <div class="row">
                    <div class="col-md-5">
                        <div class="d-flex justify-content-start">
                            <p>© 2022 desktopIP Limited. All rights reserved.</p>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="d-flex justify-content-end">
                            <a href="https://contacts.jaybod.com/helpcenter/" class="text text-blue bold-600 px-2 mx-2">Help</a>
                            <a href="https://jaybod.com/terms-service" class="text text-blue bold-600 px-2 mx-2">Terms</a>
                            <a href="https://jaybod.com/terms-service" class="text text-blue bold-600 px-2 mx-2">Privacy</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
  </div>
</template>

<script>
import NavPackage from "./nav-packages.vue"
import moment from "moment";

export default {
  name: 'PaymentHistory',
  components: {
      NavPackage
  },
  computed: {
    listPaymentHistory () {
      return this.$store.getters["packages/paymentHistory"]
    }
  },
  mounted() {
    // this.$ga.page('/payment/histories')
    this.$ga.event('payment-history-page', 'actionx', 'labelx', 1)
  },
  created () {
    this.$store.dispatch('packages/getPaymentHistory')
  },
  methods: {
    convertDate(timestamp) {
        return moment(timestamp * 1000).format('MMM DD,YYYY')
    }
  }
}
</script>

<style scoped>
h1 {
    font-size: 24px;
    font-weight: 600;
    color: #262A2C;
}
p {
    font-size: 16px;
    font-style: normal;
}
.container-history{
    margin: 0px 40px;
    padding: 50px 60px;
    padding-bottom: 0px;
}
.container-history-footer{
    margin: 0;
    padding: 0;
}
.card{
    padding: 0px 0px;
    margin: 0;
    border-radius: 0px;
    box-shadow: inset 0 0 0rem 1px rgb(0 0 0 /7%);
}
.card-body .row p{
    margin: 0;
    color: #919394;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1px;
    margin-bottom: 5px;
}
.card-body .row p span{
    color: black;
    font-size: 16px;
}
.table{
    font-size: 16px;
}
.history-info{
    min-height: 70vh;
}
.blue-text{
    color: #00AAFF;
}
.titles thead tr th{
    color: #919394;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1px;
    font-weight: 400 !important;
}
#black-text{
    color: #262A2C;
    background-color: #E9EAEA;
    border-radius: 10px;
    padding: 3px 5px;
}
#red-text {
    color: #D42020;
    background-color: #FBE9E9;
    border-radius: 10px;
    padding: 3px 5px;
}
#green-text{
    color: #009934;
    background-color: #E5F5EB;
    border-radius: 10px;
    padding: 3px 5px;
}
.note-text{
    color: #919394;
    margin-top: 20px;
    margin-left: 0px;
    font-size: 16px;
}
.default-text-style-w{
    font-weight: 500;
}
.btn-details{
    background-color: #00AAFF;
    color: #FFFFFF;
    font-size: 16px;
    padding-top: 3px;
    padding-bottom: 3px;
}
</style>
